const dev = {

    "realmapiid": "accounts-dev-ctclr",
    "realmapikey": "Ve9dvUzJyMvbT1rkjmTRalt2No5wQhge5J7y0ZQWD6K7dHn9lqFkCgDE9y49X7if",
    "apiUrl": "https://us-central1-appycx-beta.cloudfunctions.net",
    "apiUrlV2": "https://us-central1-appycx-beta.cloudfunctions.net/api/v2",
    "commonloginUrl": "https://commonlogin.pbodev.info",
    "commonloginApiUrl": "https://commonlogin.pbodev.info/backend",
    "commonBillingUrl": "https://checkout-dev.pbodev.info",
    "livechatUrl": "https://chat-staging.pbodev.info",
    "liveChatApiUrl": "https://us-central1-livechat-production.cloudfunctions.net/",
    "storageUrl": "https://chatimage.appypie.com/",
    "previewUrl": "https://chatwidget.bot/previewnew.html?tempid=",
    "iframeUrl": "https://chatwidget.bot/mobilestg.html",
    "iframeUrlLocal": "http://localhost:8015",
    "shareUrl": "https://chatwidget.bot/mobilestg.html"

};

const prod = {

    "realmapiid": "accounts-live-sdpwm",
    "realmapikey": "A1bPAGRUcGsVgupH09R3FLIblKXww60vCKGKnpJVWdCLmdMSzs12ElWCheXWG5XU",
    "apiUrl": "https://us-central1-chatbot-production-d6ea3.cloudfunctions.net",
    "apiUrlV2": "https://us-central1-chatbot-production-d6ea3.cloudfunctions.net/api/v2",
    // "apiUrlV2" : "http://localhost:5001/chatbot-production-d6ea3/us-central1/api/v2",
    "commonloginUrl": 'https://accounts.appypie.com',
    // "commonloginUrl":"https://gamaaccounts.appypie.com/",
    "commonloginApiUrl": "https://backendaccounts.appypie.com",
    "commonBillingUrl": "https://checkout.appypie.com",
    "livechatUrl": "https://chat.appypie.com",
    "liveChatApiUrl": "https://us-central1-livechat-production.cloudfunctions.net/",
    "storageUrl": "https://chatimages.appypie.com/",
    "previewUrl": "https://chatwidget.bot/mobile.html?tempId=",
    // "previewUrl":"https://chatbot.appypie.com/mobile.html?tempId=",
    // "previewUrl":"http://localhost:8014/?tempId="
    // "iframeUrl":"http://localhost:8014",
    "iframeUrl": "https://chatwidget.bot/mobile.html",
    "iframeUrlNew": "https://chatwidget.bot/mobilenew.html",
    "iframeUrlCopy": "https://chatwidget.bot/mobilecopy.html",
    // "iframeUrl":"https://stagingchatbot.pbodev.info/mobilebot.html"
    // "iframeUrl":"http://localhost:8014",
    "iframeUrlLocal": "http://localhost:8014",
    "shareUrl": "https://chatwidget.bot/mobile.html",
    "shareUrlNew": "https://chatwidget.bot/mobilenew.html",
    "shareUrlCopy": "https://chatwidget.bot/mobilecopy.html",
    "testver": "abcxyz"



};


var realmapiid;
var realmapikey;
var apiUrl;
var apiUrlV2;
var commonloginUrl;
var commonBillingUrl;
var livechatUrl;
var liveChatApiUrl;

var storageUrl;
var commonloginApiUrl
var previewUrl
var iframeUrl
var shareUrl



// console.log(process.env.REACT_APP_STAGE,"process.env.REACT_APP_STAGE")
if (process.env.REACT_APP_STAGE !== "prod") {

    realmapiid = dev.realmapiid;
    realmapikey = dev.realmapikey;
    apiUrl = dev.apiUrl;
    commonloginUrl = dev.commonloginUrl;
    commonBillingUrl = dev.commonBillingUrl;
    livechatUrl = dev.livechatUrl;
    liveChatApiUrl = dev.liveChatApiUrl;
    commonloginApiUrl = dev.commonloginApiUrl;
    storageUrl = dev.storageUrl;
    previewUrl = dev.previewUrl;
    shareUrl = dev.shareUrl
    apiUrlV2 = dev.apiUrlV2




    //configuring iframe only
    //whether to run the iframe local or not
    if (window.location.href.includes('chatbotlocal')) {
        iframeUrl = dev.iframeUrlLocal
    }
    else {
        iframeUrl = dev.iframeUrl;


    }



} else {

    realmapiid = prod.realmapiid;
    realmapikey = prod.realmapikey;
    apiUrl = prod.apiUrl;
    commonloginUrl = prod.commonloginUrl;
    commonBillingUrl = prod.commonBillingUrl;
    livechatUrl = prod.livechatUrl;
    liveChatApiUrl = prod.liveChatApiUrl;
    commonloginApiUrl = prod.commonloginApiUrl;
    storageUrl = prod.storageUrl;
    previewUrl = prod.previewUrl;
    apiUrlV2 = prod.apiUrlV2
    //configuring iframe only
    //whether to run the iframe local or not
    if (window.location.href.includes('chatbotlocal')) {
        iframeUrl = prod.iframeUrlLocal
    }
    else if (window.location.href.includes('chatbotnew')) {
        iframeUrl = prod.iframeUrlNew;
    }
    else if (window.location.href.includes('chatbotcopy')) {
        iframeUrl = prod.iframeUrlCopy;
    }
    else {
        iframeUrl = prod.iframeUrl;
    }



    //comfiguring only the shareurl
    if (window.location.href.includes('chatbotlocal')) {
        shareUrl = prod.iframeUrlLocal
    }
    else if (window.location.href.includes('chatbotnew')) {
        shareUrl = prod.shareUrlNew;
    }
    else if (window.location.href.includes('chatbotcopy')) {
        shareUrl = prod.shareUrlCopy;
    }
    else {
        shareUrl = prod.shareUrl;
    }


}





export const REALM_API_ID = realmapiid;
export const REALM_API_KEY = realmapikey;
export const API_URL = apiUrl;
export const COMMON_LOGIN_URL = commonloginUrl;
export const COMMON_BILLING_URL = commonBillingUrl;
export const LIVE_CHAT_URL = livechatUrl;
export const LIVE_CHAT_API_URL = liveChatApiUrl;
export const COMMON_LOGIN_API_URL = commonloginApiUrl;
export const STORAGE_URL = storageUrl;
export const PINECONE_NAME = "chatbot-production"
export const PINECONE_CLIENT_NAME = "chatbot-client"
export const PREVIEW_URL = previewUrl
export const IFRAME_URL = iframeUrl
export const SHARE_URL = shareUrl
export const API_URL_V2 = apiUrlV2
// "https://accounts.appypie.com/register?frompage=https://" + window.location.hostname +
// "/login%2F&website=" + "https://chatbot.appypie.com/subscribe"


// https://accounts.appypie.com/register?frompage=https://chatbot.appypie.com/subscribe%2F&website=https://chatbot.appypie.com/subscribe